<template>
    <div class="selection">
        <!-- 标签导航 -->
        <el-tabs class="headtabs" tab-position="left">
            <el-tab-pane class="el-tabs__content" v-for="(item, index) in $t('functionSelection')" :label="item.name">
                <!-- 表格内容 -->
                <el-card class="box-card" shadow="hover">
                    <!-- 说明 -->
                    <template v-if="index == $t('functionSelection').length - 1">
                        <div slot="header" class="clearfix">
                            <span>{{ item.name }}</span>
                            <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
                        </div>
                        <el-table height="calc(100vh - 250px)" :data="item.module" style="width: 100%" size="mini">
                            <el-table-column prop="name">
                            </el-table-column>
                        </el-table>
                    </template>
                    <!-- 其余表格内容 -->
                    <template v-else>
                        <div slot="header" class="clearfix">
                            <span>{{ item.name }}</span>
                            <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
                        </div>

                        <el-table class="tableone" height="calc(100vh - 250px)" :data="item.module" style="width: 100%">
                            <el-table-column  align="center" prop="name" label="模块" width="200vw">
                            </el-table-column>
                            <el-table-column  align="center" label="子功能">
                                <template slot-scope="scope">

                                    <el-table  style="width: 100%" :data="item.module[scope.$index].childModule" size="mini">

                                        <el-table-column align="center"  prop="name" label="名称" width="250vw">
                                        </el-table-column>
                                        <el-table-column align="center"  prop="kind" label="类型">
                                        </el-table-column>
                                        <el-table-column align="center"  prop="money" label="价格">
                                        </el-table-column>
                                        <el-table-column align="center"  type="selection" width="100vw"  >
                                        </el-table-column>
                                    </el-table>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-card>
            </el-tab-pane>
        </el-tabs>
        <div class="buttondiv">
            <el-button class="button" type="primary" round @click="dialogFormVisible = true">咨询客服</el-button>
            <el-dialog  style="width: 60vw;margin-left: auto;margin-right: auto;"  title="客户资料" :visible.sync="dialogFormVisible">
                <el-form :model="form">
                    <el-form-item label="您的姓名" :label-width="formLabelWidth">
                        <el-input style="width: 20vw;" v-model="form.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="公司名称" :label-width="formLabelWidth">
                        <el-input style="width: 20vw;"  v-model="form.team" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" :label-width="formLabelWidth">
                        <el-input style="width: 20vw;"  v-model="form.phone" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div  slot="footer" class="dialog-footer">
                    <el-button @click="open(false)">取 消</el-button>
                    <el-button type="primary" @click="open(true)">确 定</el-button>
                </div>
            </el-dialog>
        </div>

    </div>
</template>
  
<script>


export default {
    name: 'functionSelection',
    data() {
        return {
            formLabelWidth: '5vw',
            dialogFormVisible: false,
            form: {
                name: '',
                team: '',
                phone: '',
            },

        }
    },
    components: {

    },
    methods: {
        open(flag) {
            this.dialogFormVisible = false
            if(flag == true){
                this.$message({
                    type: 'success',
                    message: '欢迎您，' + this.form.name + '，我们会尽快与' + this.form.team + '联系，请保持电话' + this.form.phone + '能够随时接听。'
                });
            }
            else if(flag == false){
                this.$message({
                    type: 'info',
                    message: '欢迎下次咨询'
                });
            }
        }
    }
}
</script>
<style scoped lang="scss">
.selection {
    // position: relative;
    // display: flex;
    // justify-content:space-around;
    width: 70vw;
    height: auto;
    margin-bottom: 10vh;
    margin-top: 13vh;
    margin-left: 10vw;
    margin-right: 50vw;

    .buttondiv {
        padding-left: 15vw;
        padding-top: 5vh;
        position: relative;
        display: flex;
        justify-content: center;

        .button {
            width: 50vw;
            height: 5vh;

        }

        .dialog-footer{
            position: relative;
            display: flex;
            justify-content:center;
        }
    }



    .clearfix {
        text-align: center;
        font-size: large;
        font-weight: bolder;
        // background: linear-gradient(to right, rgba(255, 54, 54, 0.01), rgba(255, 200, 98, 0.178), rgba(255, 255, 0, 0.13), rgba(0, 128, 0, 0.13), rgba(0, 0, 255, 0.144), rgba(76, 0, 130, 0.137), rgba(238, 130, 238, 0.13));
    }

    .box-card {
        margin-bottom: 5vh;
        margin-left: 5vw;
        background: linear-gradient(to right, rgba(255, 54, 54, 0.1), rgba(255, 200, 98, 0.1), rgba(255, 255, 0, 0.1), rgba(0, 128, 0, 0.1), rgba(0, 0, 255, 0.1), rgba(76, 0, 130, 0.1), rgba(238, 130, 238, 0.1));
        border: 0;
        .clearfix:before,
        .clearfix:after {
            display: table;
            content: "";
        }

        .clearfix:after {
            clear: both
        }

        .tableone {
            padding-top: 0;
            margin-top: -2vh;
            // position: relative;
            // display: flex;
            // justify-content:center;
        }
    }

}
</style>
  